import APIServer from './APIServer'


export default class ZoneAPI extends APIServer{ 
	
	async getAllZones(includeEvents) {
		var url = "/zones"
		if (includeEvents) { 
			url += "?include_events=True"
		} 
		return await super.get(url)
	}

	async getZone(zone) { 
		var url = "/zones"
		if (zone !== undefined && zone !== null) { 
			url += "?zone=" + zone
		}
		return await super.get(url)
	}

	async getZones(zones) { 
		var url = "/zones"
		if (zones) { 
			url += "?zones=" + zones.toString()
		}
		return await super.get(url)
	}

	async getCoordinates() {
		try {
			const data = await fetch(`https://pro.ip-api.com/json/?key=${process.env.ipServerApiKey}`, {
				mode: 'cors',
				headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				}
			})
				.then(res => this.handleErrors(res))
				.catch((error) => { return error })

			return data
		} catch {
			return undefined
		}
	}

	async getZoneWithCoordinates(lat, lon) { 
		var url = "/zones"
		if (lat !== undefined && lat !== null) { 
			url += "?lat=" + lat + "&lon=" + lon 
		}
		return await super.get(url)
	}

	async getConfig(types, zoneSlug=null) { 
		var url = `/config?context=` + types.toString()
		if (zoneSlug) { 
			url += "&zone_slug=" + zoneSlug
		}
		return await super.get(url)
	}
}